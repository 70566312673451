<route>
{
  "meta": {
    "permission": [
      "inventory.view_movement"
    ]
  }
}
</route>

<template>
  <v-container fluid>
    <v-card>
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(submit)">
          <i-toolbar :title="$tc('movement', 1)" :loading="load"></i-toolbar>
          <v-card-text class="px-0">
            <v-row>
              <v-col
                v-if="movement.product"
                cols="12"
                sm="3"
                :class="{
                  'pr-3': $vuetify.breakpoint.smAndUp
                }"
              >
                <v-skeleton-loader
                  v-if="loading"
                  type="text"
                  tile
                ></v-skeleton-loader>
                <v-text-field
                  outlined
                  v-show="!loading"
                  key="code-input"
                  v-model="product.internalCode"
                  disabled
                  color="secondary"
                  :label="$tc('code', 1)"
                />
              </v-col>
              <v-col cols="12" lg="3" md="6" sm="6">
                <ValidationProvider
                  vid="product_"
                  :name="$tc('product', 1)"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-autocomplete
                    autocomplete="off"
                    v-model="movement.product"
                    outlined
                    :error-messages="errors[0]"
                    :label="$tc('product', 1)"
                    class="secondary--text"
                    :items="productsFiltered"
                    item-text="name"
                    item-value="pk"
                    append-icon="fa-search"
                    key="product_-input"
                    clearable
                  ></v-autocomplete>
                </ValidationProvider>
              </v-col>
              <v-col
                cols="12"
                md="3"
                v-if="movement.product && product.quantity"
              >
                <ValidationProvider
                  vid="movement_type"
                  :name="$t('movement_type')"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-select
                    outlined
                    v-model="movement.movement_type"
                    :error-messages="errors[0]"
                    item-text="state"
                    item-value="abbr"
                    :items="[
                      {
                        state: $t('incorporation'),
                        abbr: 'incorporation'
                      },
                      {
                        state: $t('deincorporation'),
                        abbr: 'deincorporation'
                      }
                    ]"
                    :label="$tc('movement_type')"
                    :data-vv-as="$t('movement_type')"
                  ></v-select>
                </ValidationProvider>
              </v-col>
              <v-col
                v-if="movement.product && product.quantity"
                cols="12"
                sm="6"
                md="3"
              >
                <ValidationProvider
                  vid="quantity"
                  :name="$t('quantity')"
                  :rules="{
                    required: true,
                    max_value:
                      movement.movement_type === 'deincorporation'
                        ? Number(product.quantity).toFixed(2)
                        : Number.MAX_VALUE.toFixed(2)
                  }"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    v-model="movement.quantity"
                    name="quantity"
                    outlined
                    :error-messages="errors[0]"
                    :label="$t('quantity')"
                    class="secondary--text"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="my-0 py-0">
                <v-row class="mt-0 py-0">
                  <v-col cols="12" sm="3" v-if="movement.product">
                    <v-skeleton-loader
                      v-if="loading"
                      type="text"
                      tile
                    ></v-skeleton-loader>
                    <v-text-field
                      outlined
                      disabled
                      v-show="!loading"
                      v-model="product.min_stock"
                      color="secondary"
                      :label="$tc('minStock', 1)"
                      key="minStock-input"
                    />
                  </v-col>
                  <v-col v-if="movement.product" cols="12" sm="3">
                    <v-skeleton-loader
                      v-if="loading"
                      type="text"
                      tile
                    ></v-skeleton-loader>
                    <v-text-field
                      outlined
                      disabled
                      v-show="!loading"
                      v-model="product.quantity"
                      color="secondary"
                      :label="$tc('total', 1)"
                      key="total-input"
                    />
                  </v-col>
                  <v-col v-if="movement.product" cols="6" sm="2">
                    <v-skeleton-loader
                      v-if="loading"
                      type="button"
                      tile
                    ></v-skeleton-loader>
                    <v-switch
                      disabled
                      v-show="!loading"
                      v-model="product.active"
                      :label="$t('active')"
                      name="active"
                      color="primary"
                      key="active-input"
                    ></v-switch>
                  </v-col>
                  <v-col v-if="movement.product" cols="12" sm="2">
                    <v-skeleton-loader
                      v-if="loading"
                      type="button"
                      tile
                    ></v-skeleton-loader>
                    <v-switch
                      disabled
                      v-show="!loading"
                      v-model="product.tax"
                      :label="$tc('globaltax', 1)"
                      name="tax"
                      color="primary"
                      required
                      key="tax-input"
                    ></v-switch>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </form>
      </ValidationObserver>
    </v-card>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      loading: false,
      load: false,
      product: null,
      searchProduct: '',
      products: [],
      movement: {
        product: null,
        own_prod: '',
        movement_type: '',
        own_user: '',
        quantity: 0,
        updated_user: '',
        updated_date: this.$moment().format('YYYY-MM-DD')
      }
    }
  },
  computed: {
    ...mapGetters({
      me: 'session/me'
    }),
    productsFiltered() {
      let productsFiltered = []
      this.products.forEach((element) => {
        if (element.can_be != 'SALE') productsFiltered.push(element)
      })
      return productsFiltered
    }
  },
  watch: {
    'movement.product': {
      handler(val) {
        let inx = this.products.findIndex((m) => val == m.pk)
        if (inx > -1) {
          this.product = this.products[inx]
        }
      }
    },
    searchProduct(val) {
      if (val == null || (typeof val === 'string' && val.length % 2 == 0)) {
        this.getProduct()
      }
    }
  },
  mounted() {
    this.getMovement(this.$route.params.code)
    this.getProduct()
  },
  methods: {
    async getProduct() {
      this.isLoading = true
      try {
        const product_ = await this.$api.product.list({
          opt: { params: { dropdown_list: true } }
        })
        this.products = product_.data
      } finally {
        this.isLoading = false
      }
    },
    async getMovement() {
      let validation = this.codification({
        mode: 'decode',
        code: this.$route.params.pk
      })
      if (validation === 'create') {
        if (this.$store.getters['utils/getIsloading']) {
          this.$store.dispatch('utils/actionLoading')
          this.$route.meta.title = this.$t('create', { model: '' })
        }
        this.movement = {
          product: null,
          own_prod: '',
          movement_type: '',
          own_user: this.me.username,
          quantity: 0,
          updated_user: this.me.id,
          updated_date: this.$moment().format('YYYY-MM-DD')
        }
      } else {
        try {
          this.loading = true
          let response = await this.$api.movement.show({ pk: validation })
          this.movement = response.data
          this.$route.meta.title = this.$t('edit', { model: '' })
        } finally {
          this.loading = false
        }
      }
      if (this.movement.product != null) {
        try {
          let product = await this.$api.product.show({
            pk: this.movement.product
          })
          this.products = [product.data]
          this.product = product.data
        } catch (err) {
          this.movement.product = null
          this.product = null
        }
      } else {
        this.getProduct()
      }
    },
    async submit() {
      try {
        this.load = true
        this.movement.pk === undefined || this.movement.pk === ''
          ? await this.$api.movement.create({
              form: this.movement
            })
          : await this.$api.movement.edit({
              pk: this.movement.pk,
              form: this.movement
            })
        this.$toast.success(
          `${this.$tc('movement', 1)} ${this.$tc(
            this.movement.pk !== undefined ? 'edited' : 'created',
            2
          )}`
        )
        this.$router.push({ name: 'movement' })
      } finally {
        this.load = false
      }
    }
  }
}
</script>
